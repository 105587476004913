










@import 'style';

.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-content {
    padding: 0!important;
    border-radius: 3rem!important;
    overflow: hidden;
    background: $white!important;
    pointer-events: none!important;
    @include shadow-8;
}

.mapboxgl-popup {
    pointer-events: none;
}

.mapboxgl-popup-anchor-bottom, .mapboxgl-popup-anchor-bottom-right, .mapboxgl-popup-anchor-bottom-left {
    top: -10rem;
    .mapboxgl-popup-tip {
        border-top-color: $white!important;
        border-top-color: var(--popup-color)!important;
    }
}
.mapboxgl-popup-anchor-top, .mapboxgl-popup-anchor-top-right, .mapboxgl-popup-anchor-top-left  {
    top: 10rem;
    .mapboxgl-popup-tip {
        border-bottom-color: $white!important;
    }
}
.mapboxgl-popup-anchor-left, .mapboxgl-popup-anchor-left-top  {
    left: 10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
    }
}
.mapboxgl-popup-anchor-left-bottom {
    left: 10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
        border-right-color: var(--popup-color)!important;
    }
}
.mapboxgl-popup-anchor-right, .mapboxgl-popup-anchor-right-top {
    left: -10rem;
    .mapboxgl-popup-tip {
        border-left-color: $white!important;
    }
}
.mapboxgl-popup-anchor-right-bottom {
    left: -10rem;
    .mapboxgl-popup-tip {
        border-right-color: $white!important;
        border-right-color: var(--popup-color)!important;
    }
}

.mapboxgl-popup-tip {
    border: 5rem solid transparent;
}


.rx-page-explore-layers-routes-popup-1 {
	display: flex;
    flex-direction: column;
    justify-content: space-around;
	pointer-events: auto;
    min-width: 186rem;
    min-height: 88rem;
	color: rgb(0, 0, 0);
	margin: 0;
	text-decoration: none;
	z-index: 4;
    line-height: normal;
    border: none;
    padding: 8rem 12rem;
}

.rx-page-explore-layers-routes-popup-2 {
    @include text-label;
    font-size: 16rem;
    line-height: 20rem;
    color: $gray-90;

}

.rx-page-explore-layers-routes-popup-3 {
    @include text-label;
    color: $gray-70;
}